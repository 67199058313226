import React from "react"
import { Container, Row, Col } from "reactstrap"
import BackgroundImage from "../backgroundImage"

function Services() {
    return (
        <React.Fragment>
            <BackgroundImage src="section.jpg" className="section-banner">
                <div className="overlay text-light justify-content-center text-center py-5 px-2">
                    <h2 className="mb-3">We’ve got your home needs covered, too.</h2>
                    <h6>Compare quotes for hot tubs, security systems and more</h6>
                </div>
            </BackgroundImage>
            <div className="p-md-5 p-2 text-left">
                <Container>
                    <Row>
                        <Col xs={12} md={6} className="mt-5">
                            <h2>We’ve helped millions of businesses save time and money.</h2>
                            <p className="mt-4">
                                Making important purchases for your business is a tough task.
                                You could call individual companies and fight your way through a
                                maze of recordings, but that takes time and time is money. Let
                                BuyerZone quickly find the right seller for your needs who will
                                compete to win your business.
              </p>
                        </Col>
                        <Col xs={12} md={6} className="mt-5">
                            <h2>Looking to fill your sales pipeline?</h2>
                            <br />
                            <p className="mt-md-4">
                                We’re always looking for quality sellers. Our lead generation
                                programs have helped thousands of clients close more business.
                                Connect with pre-screened, in-market buyers today.
              </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Services
