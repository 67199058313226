import React, { useState } from "react"
import { Form, FormGroup, Input, Button, FormText } from "reactstrap"
import _ from "lodash"
import Swal from "sweetalert2"
import ReCAPTCHA from "react-google-recaptcha"

import BackgroundImage from "../backgroundImage"

const SITE_KEY = "6LetxLcZAAAAABJ5ztKDONESO-CZNlF69bSUXXAp" //process.env.GATSBY_RECAPTCHA_KEY

function ContactUs() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState({})
  const [state, setState] = React.useState({
    callback: "not fired",
    value: "",
    expired: false,
  })
  const _reCaptchaRef = React.createRef()

  const handleSubmit = () => {
    if (!validate()) return

    let body = new FormData(body)
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)

    fetch("https://api.usaveservices.com/index.php", {
      method: "POST",
      mode: "no-cors",
      "Access-Control-Allow-Origin": "*",
      body,
    })
      .then(({}) => {
        setEmail("")
        setMessage("")
        setName("")
        Swal.fire({
          title: "Thank You",
          text: "We will contact you soon.",
          icon: "success",
          confirmButtonText: "Okay",
        })
      })
      .catch(({}) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong at our end. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        })
      })
  }

  const validate = () => {
    const errors = {}
    if (_.isEmpty(name)) errors.name = "name can not be empty"
    if (!email.includes("@")) errors.email = "Please enter a valid email"
    if (_.isEmpty(email)) errors.email = "email can not be empty"
    if (_.isEmpty(message)) errors.message = "name can not be empty"
    if (_.isEmpty(state.value))
      errors.recaptcha = "please verify that you are not a robot"
    setError(errors)
    return _.isEmpty(errors)
  }

  const handleChange = value => {
    setError({ ...error, recaptcha: "" })
    setState({ value })
    if (value === null) setState({ expired: true })
  }

  const remvoeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" })
  }

  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" })
  }

  return (
    <BackgroundImage src="contact-us-bg.jpg" className="section-banner">
      <div className="overlay d-flex flex-column align-items-center p-5">
        <h1 className="text-light text-center">Have Some Questions?</h1>
        <br />
        <div className="form-wrapper">
          <Form className="p-4 p-md-5 justify-content-center d-flex flex-column">
            <FormGroup>
              <Input
                type="text"
                name="name"
                onFocus={remvoeError}
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                placeholder="Your Name"
              />
              {error.name && (
                <span className="text-danger">
                  <small>{error.name}</small>
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                value={email}
                onFocus={remvoeError}
                onChange={({ target: { value } }) => setEmail(value)}
                placeholder="Email"
              />
              {error.email && (
                <span className="text-danger">
                  <small>{error.email}</small>
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="message"
                rows="5"
                value={message}
                onFocus={remvoeError}
                onChange={({ target: { value } }) => setMessage(value)}
                placeholder="Your Question"
              />

              {error.message && (
                <span className="text-danger">
                  <small>{error.message}</small>
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey={SITE_KEY}
                onChange={handleChange}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
              <br />
              {error.recaptcha && (
                <span className="text-danger">
                  <small>{error.recaptcha}</small>
                </span>
              )}
            </FormGroup>
            <Button
              className="mt-3"
              size="md"
              color="primary"
              onClick={handleSubmit}
            >
              Send Message
            </Button>
          </Form>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default ContactUs
