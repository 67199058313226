import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"

const data = [
  {
    title: "professional",
    items: [
      { name: "Business Loan", path: "/works/business-loans-2" },
      { name: "Business Phone Systems", path: "/works/business-phone-systems" },
      { name: "Credit Card Processing", path: "/works/credit-card-processing" },
      { name: "Payroll", path: "/works/payroll-services" },
      { name: "POS Systems", path: "/works/pos-systems" },
    ],
  },
  {
    title: "Home",
    items: [
      {
        name: "Bath & Shower Replacement",
        path: "/direct/bath-shower-replacement",
      },
      { name: "Home Security", path: "/direct/home-security-system" },
      { name: "Hot Tub", path: "/direct/hot-tubs" },
      { name: "Pest Control", path: "/direct/pest-control-services" },
      {
        name: "Residential Storage Containers",
        path: "/direct/residential-storage-containers",
      },
    ],
  },
  {
    title: "construction",
    items: [
      { name: "Aerial Lifts", path: "/direct/aerial-lifts" },
      { name: "Backhoe Loaders", path: "/direct/backhoe-loaders" },
      { name: "Bulldozers", path: "/direct/bulldozers" },
      { name: "Excavators", path: "/direct/excavators" },
    ],
  },
  {
    title: "health care",
    items: [
      {
        name: "Electronic Health Records",
        path: "/direct/electronic-health-records",
      },
      {
        name: "Medical Billing Services",
        path: "/direct/medical-billing-services",
      },
      {
        name: "Medical Transcription",
        path: "/direct/medical-transcription-services",
      },
      { name: "Swin Spa", path: "/direct/swim-spas" },
    ],
  },
]

function Categories() {
  return (
    <div className="p-md-5 px-2 py-5">
      <Container>
        <h2 className="text-uppercase text-color-blue text-left display-4">
          Popular Services
        </h2>
        <Row>
          {data.map(({ title, items }) => (
            <Col md={6} xs={12} className="mt-5" key={Math.random()}>
              <h1 className="text-uppercase text-color-secondry">{title}</h1>
              <div className="divider-dark w-75" />
              <div className="horizontal-line w-50" />
              <ul className="categories-list mt-3">
                {items.map(item => (
                  <Link
                    key={Math.random()}
                    to={item.path}
                    className="categories-link"
                  >
                    <li>{item.name}</li>
                  </Link>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Categories
