import React from "react"
import Image from "../image"

const data = [
    {
        title: "SHARE NEEDS",
        content:
            "Tell us just a few basic details about what you’re looking to buy. Our quick forms will guide you through the key questions to consider.",
        url: "share-min.png",
    },
    {
        title: "GET CONNECTED",
        content:
            "We’ll introduce you to a few, hand-selected sellers who will provide price quotes and answer any questions you may have.",
        url: "connection-min.png",
    },
    {
        title: "BUY SMART",
        content:
            "You’ll compare multiple price quotes so you know you’re getting the best deal. Plus, our handy Buyer’s Guides will prep you for the ins and outs.",
        url: "smart-min.png",
    },
]

function Works() {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center pt-5 pb-md-5">
            <div className="d-flex justify-content-center w-100">
                <div className="w-25" style={{ minWidth: 250 }}>
                    <Image src="sticker-min.png" />
                </div>
            </div>
            <div className="mt-5 w-100 h-100 d-flex flex-wrap justify-content-center">
                {data.map(({ title, content, url }) => (
                    <div className="mt-3 p-3 text-center m-5 works-card" key={Math.random()}>
                        <div className="d-flex justify-content-center">
                            <div className="mb-2" style={{ minWidth: 70 }}>
                                <Image src={url} />
                            </div>
                        </div>
                        <h3 className="text-color-dark mb-4">{title}</h3>
                        <p >{content}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Works
