import React from "react"
import BackgroundImage from "../backgroundImage"

function Banner() {

    const handleScroll = () => {
        window.scrollTo({
            top: window.innerHeight - 50,
            behavior: "smooth",
        })
    }

    return (
        <React.Fragment>
            <BackgroundImage className="banner" src="banner-min.webp">
                <div className="d-flex flex-column text-center align-items-center justify-content-center h-100 overlay p-3">
                    <h1 className={`text-color-blue h1 eras-itc`}>We Help You Buy Smart!</h1>
                    <br />
                    <h4 className="text-light font-weight-lighter">
                        Compare quotes on essential services and equipment for your <br />{" "}
                        home or business.
                    </h4>
                    <div
                        onClick={handleScroll}
                        role="button"
                        className="scroll-button-wrapper"
                    >
                        <img
                            alt="icon arrow"
                            src={require("../../images/arrow.png")}
                            width={50}
                            className="scroll-icon pointer"
                        />
                    </div>
                </div>
            </BackgroundImage>
        </React.Fragment>
    )
}

export default Banner
